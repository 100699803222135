@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

@font-face {
    font-family: 'Astera';
    src: url('./assets/fonts/astera.eot');
    src: url('./assets/fonts/astera.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/astera.ttf') format('truetype');
}

// @import '~swiper/dist/css/swiper.min.css';
@import '~swiper/swiper-bundle.min.css';

@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/theme";